<template>
  <div class="fixed left-8 bottom-0 hidden lg:flex flex-col items-center gap-6">
    <!-- Social Links -->
    <a v-for="social in socials" :key="social.name" :href="social.url" target="_blank" rel="noopener noreferrer"
      class="text-gray-400 hover:text-green-400 transform hover:-translate-y-1 transition-all duration-300">
      <component :is="social.icon" v-if="social.icon" class="w-5 h-5" />
      <svg v-else-if="social.name === 'X'" class="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M4 4l11.733 16h4.267l-11.733 -16z" />
        <path d="M4 20l6.768 -6.768m2.46 -2.46l6.772 -6.772" />
      </svg>
      <span class="sr-only">{{ social.name }}</span>
    </a>

    <!-- Vertical Line -->
    <div class="w-px h-24 bg-gray-400/20 mt-4"></div>
  </div>
</template>

<script setup>
import { GithubIcon, LinkedinIcon } from 'lucide-vue-next'

const socials = [
  {
    name: 'GitHub',
    url: 'https://github.com/Nabil-nl',
    icon: GithubIcon
  },
  {
    name: 'LinkedIn',
    url: 'https://linkedin.com/in/nabil-ouhmida',
    icon: LinkedinIcon
  },
  {
    name: 'X',
    url: 'https://x.com/1nbl_0x01', 
    icon: null,
  },
]
</script>

<style scoped>
/* Add any additional custom styles here if needed */
</style>